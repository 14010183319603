import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
import DatePicker from 'vue2-datepicker';
import updateCalibration from './updateCalibration';
export default {
  name: 'weighingMachine',
  components: {
    updateCalibration,
    DatePicker
  },
  watch: {
    currentPage: function() {
      this.getWeighingMachineData();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getWeighingMachineData();
    }
  },
  data() {
    return {
      uploadRowDetail: null,
      dunningLetterMasterId: null,
      uploadCalibrationModal: false,
      parent_value_set_id: null,
      showValueSetModal: false,
      payload: null,
      unsubscribe: null,
      currentPage: 1,
      totalRows: null,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      loader: false,
      vsetCode: null,
      setTimeVsetCode: null,
      calibrationFromDate: null,
      calibrationToDate: null,
      calibrationDue: null,
      state: null,
      serialNo: null,
      weighMachineData: [
        {
            serial_no: '1'
        }
      ],
      weighMachineFields: [
        {
          key: 'serial_no',
          label: 'S.No.'
        },
        {
          key: 'weighing_machine',
        },
        {
          key: 'location'
        },
        {
          key: 'last_calibration_date',
        },
        {
          key: 'last_calibration_state',
        },
        {
          key: 'calibration_due',
        },
        {
          key: 'upload_calibration'
        }
      ]
    };
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          // this.uploadCalibrationModal = false;
          this.uploadRowDetail = null;
          this.dunningLetterMasterId = null;
        }
      }
    });
  },
  methods: {
    disabledDates(date) {
      return commonHelper.disabledDates(date, this.calibrationFromDate);
    },
    getWeighingMachineData() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        customer_name: this.customerName,
        erp_customer_no: this.erpCustomerNumber,
        short_name: this.customerShortName,
        is_rule_mapped: this.active
      };
      this.loader = true;
      this.$store
        .dispatch('dunningAdmin/getWeighingMachineData', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.weighMachineData = resp.data.data.page;
            this.totalRows = resp.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.DUNNING_RULE_LIST) {
        this.ruleMasterId = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.DUNNING_RULE_LIST) {
        this.mapLetter.text = item.value_code;
        this.mapLetter.value = item.value_set_dtl_id;
      }
      this.showValueSetModal = false;
    },

    openValueSetModal(vsetCode) {
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },

    closeValueSetModal() {
      this.showValueSetModal = false;
    },

    clear() {
      this.serialNo = null;
      this.calibrationFromDate = null;
      this.calibrationToDate = null;
      this.calibrationDue = null;
      this.state = null;
      this.weighMachineData = [];
      this.parent_value_set_id = null;
      this.totalRows = null;
    },
    // rowSelected(item) {
    //   this.showAddCustomerRuleMappingModal = item;
    //   this.uploadRowDetail = item;
    //   this.showHideCustomerRuleMappingModal(true);
    // },
    // openLegalEntityMap(flag, item) {
    //   this.showlegalEntityMappingForm = flag;
    //   this.flexfieldRowData = item;
    // },
    showHideUploadCalibrationModal(flag, item) {
      this.uploadCalibrationModal = flag;
      this.uploadRowDetail = item;
      if (!flag) {
        this.getDunningCustomerRuleMappingList();
      }
    }
  },
  beforeDestroy() {
    clearTimeout(this.setTimeVsetCode);
    this.unsubscribe();
  }
};
