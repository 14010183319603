// import commonHelper from '@/app/utility/common.helper.utility';
// import appStrings from '@/app/utility/string.utility';
import DatePicker from 'vue2-datepicker';
export default {
  name: 'updateCalibration',
  components: {
    DatePicker
  },
//   watch: {
//     currentPage: function() {
//       this.getWeighingMachineData();
//     },
//     perPage: function() {
//       this.currentPage = 1;
//       this.getWeighingMachineData();
//     }
//   },
  data() {
    return {
      uploadCalibrationModal: false,
      isSuccess: false,
      showAlert: false,
      respMessage: null,
      editMode: false,
      payload: null,
      unsubscribe: null,
      loader: false,
      calibrationDate: null,
      state: null,
      serialNo: null
    };
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save' && this.editMode) {
            this.addEditAddress();
        } else if (actionName === 'update') {
            this.editMode = true;
        }
      }
    });
  },
  methods: {
    addEditAddress() {
        const addressDetail = this.addressList.map(address => {
          return {
            active: address.active,
            address_id: address.address_id,
            emp_address_sequence: address.emp_address_sequence
          };
        });
        const payload = {
          address_details: addressDetail,
        //   employee_id: store.state.shared.empId
        };
        this.loader = true;
        this.$store
          .dispatch('hrms/addEditAddress', payload)
          .then(response => {
            this.loader = false;
            this.showAlert = true;
            if (response.status === 200) {
              this.editMode = false;
              this.isSuccess = true;
              this.respMessage = response.data.message;
            } else {
              this.isSuccess = false;
              this.respMessage = response.response.data.message;
            }
          })
          .catch(() => {
            this.loader = false;
          });
      },
  },
  beforeDestroy() {
    clearTimeout(this.setTimeVsetCode);
    this.unsubscribe();
  }
};
